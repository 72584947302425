:root {
  /* Cor do background */
  --background-color: #F8F9FA;

  /* Cor do header, footer e outros elementos principais */
  --primary-color: #1E1E1E;

  /* Cor dos painéis */
  --secundary-color: #E9ECEF;

  /* Cor do texto nos painéis */
  --text-color-primary-light: #E0E0E0;

  /* Cores do botão principal */
  --button-color-primary: #0056B3;
  --button-color-primary-hover: #003D82;
  --button-color-primary-wrong: #D32F2F;
  --button-color-primary-wrong-hover: #B71C1C;

  --button-color-primary-right: #388E3C;
  --button-color-primary-right-hover: #2E7D32;

  --text-color-primary: #212529;
  --text-color-secondary: #FFFFFF;
  
  --button-color-secondary: #0056B3;
  --separator-color-primary: rgba(108, 117, 125, 0.48);

  --block-color: #FFFFFF;
  --block-color-secundary: #E9ECEF;

  --color-surface: #FFFFFF;
  --color-surface-variant: #343A40;

  --loader-color: #0056B3;
  --panel-border-color: #CED4DA;

  --modal-background-color: rgba(255, 255, 255, 0.95);

  --footer-heigth: 48px;

  /* Espaçamentos padrões */
  --default-spacing: 8px;
  --double-default-spacing: 16px;
  --triple-default-spacing: 24px;

  --default-max-width: 1080px;

  --default-border-radius: 8px;
  --default-border-radius-extra: 16px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: var(--background-color);
  color: var(--text-color-secondary);
  width: 100%;
  height: 100%;
  text-align: justify;
}

.app {
  display: flex;
  margin: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.containerpage table{
  margin-top: 16px;
  width: 100%;
}

table{
  border-radius: var(--default-border-radius);
  border: solid 1px var(--separator-color-primary);
  margin-top: var(--default-spacing);
}

table, tr, td{
  align-items: center;
  text-align: center;
  padding: var(--default-spacing);
  border-bottom: solid 1px var(--separator-color-primary);
  border-spacing: 0px;
}

th{
  padding: var(--default-spacing);
}

.posicao{
  font-weight: bold;
  background-color: var(--secundary-color);
}

.containerpage-type1 {
  display: flex;
  margin-top: var(--double-default-spacing);
  margin-bottom: var(--double-default-spacing);
  padding: var(--double-default-spacing) var(--default-spacing);
  color: var(--text-color-primary);
  max-width: var(--default-max-width);
  gap: var(--double-default-spacing);
  overflow: hidden;
}

.botoes-type2 {
  display: flex;
  gap: var(--double-default-spacing);
  flex-flow: wrap;
  justify-content: center;
}

.botao-type2 {
  padding: 32px;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));
  text-decoration: unset;
  color: var(--text-color-primary);
  display: flex;
  gap: 10px;
  text-align: center;
  border-radius: 18px;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 180px;
  background-color: var(--secundary-color);
  fill: var(--text-color-primary-light);
  height: 180px;
}

.botao-type2:hover {
  filter: brightness(0.6);
}

.paragrafo1 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--text-color-secondary);
  font-family: "Nunito Sans", Arial, sans-serif;
  
}

.paragrafo2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  margin-top: var(--double-default-spacing);
  margin-bottom: var(--double-default-spacing);
  color: var(--text-color-secondary);

}

.paragrafo3 {
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  margin-top: var(--double-default-spacing);
  margin-bottom: var(--double-default-spacing);
  color: var(--text-color-secondary);
}

.mainBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.mainWrapper {
  display: flex;
  place-content: center;
}

.insideBlock {
  overflow: hidden;
  width: 100%;
  margin-bottom: -180px;
}

.mainGeometric {
  width: calc(100% + 700px);
  background-color: var(--primary-color);
  height: 500px;
  margin-left: -348px;
  transform: rotate(-4deg);
  margin-top: -365px;
  margin-bottom: 70px;
  z-index: -2;
  position: relative;
}

.containerpage {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-spacing);
  height: 100%;
  margin-bottom: var(--footer-heigth);
  padding: var(--double-default-spacing) var(--default-spacing);
  color: var(--text-color-primary);
  max-width: var(--default-max-width);
}

.new-h1 {
  font-size: 25px;
  font-weight: 400;
  margin: 0 0 var(--double-default-spacing);
  padding: 0;
  color: var(--text-color-primary-light);
}

.new-h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 24px;
  padding: 0;
  color: var(--text-color-primary-light);
}

.separator {
  height: 1px;
  border-bottom: 1px solid var(--separator-color-primary);
  width: 100%;
  max-width: 1080px;
}

.separator--withMargins {
  margin-top: 12px;
  margin-bottom: 12px;
}

.containerpage-type3 {
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 100%;
  margin-bottom: 18px;
  padding: 20px 8px;
  color: var(--text-color-primary);
  max-width: 1080px;
  gap: 30px;
  flex-flow: wrap;
  justify-content: center;
}

.containerpage-type3-block {
  padding: 16px;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));
  text-decoration: unset;
  gap: var(--default-spacing);
  text-align: center;
  border-radius: var(--default-border-radius-extra);
  background-color: var(--secundary-color);
  max-width: 330px;
  height: 270px;
}

.botao {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  font-size: 20px;
  padding: 10px;
  border-radius: 6px;
  color: var(--text-color-secondary);
  cursor: pointer;
  transition: all 0.5s;
  background-color: var(--button-color-primary);
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));

}

.botao:hover {
  color: var(--text-color-secondary);
}

/* HeaderDoc */

.varBarResponsive {
  width: 100%;
  margin: 0;
  padding: 0;
}

.conNav {
  width: 100%;
  background-color: var(--primary-color);
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
  display: flex;
  justify-content: center;
}

.toolNav {
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  max-width: 1080px;
  align-items: center;
}

.varBarResponsive a {
  text-decoration: none;
  color: var(--text-color-secondary);
  font-size: 19px;
}

/* FooterDoc */

footer {
  font-size: 8px;
  padding: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--text-color-secondary);
  background-color: var(--primary-color);
  text-align: center;
}

footer a {
  color: var(--text-color-secondary);
  text-decoration: none;
}

.button-base {
  cursor: pointer;
  background-color: var(--primary-color);
  padding: 8px 16px 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  color: var(--text-color-secondary);
  border: none;
  transition: all 0.5s;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));

}

.button-base:hover {
  background-color: var(--button-color-secondary);
  ;
  color: var(--text-color-secondary);
}

.varBarResponsive {
  background-color: var(--primary-color);
  width: 100%;
  margin: 0;
  padding: 0;
}

.conNav {
  width: 100%;
  background-color: var(--primary-color);
}

.toolNav {
  width: 100%;
  background-color: var(--primary-color);
}

.varBarResponsive a {
  text-decoration: none;
  color: var(--text-color-secondary);
  font-size: 19px;
}

.dadosResumidos{
  
}

.dados{
  width: 100%;
}

.dadosResumidos > * {
  margin-top: var(--default-spacing);
}

.dadosResumidos-h3 > * {
  margin-top: 24px;
}

.descricao{
  padding: 5xp;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.descricao + .descricao{
  margin-top: 16px;
}

.descricao h4{
}

.dadosResumidos a{
  text-decoration: none;
  cursor: pointer;
}

.descricaoQuestao > *{
  margin-top: var(--default-spacing);
}

.descricaoQuestao > .separator{
  margin-top: 24px;
  margin-bottom: var(--default-spacing);
}


.loaddingDiv{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--loader-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loader-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login{
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.login-wrapper{
  display: flex;
  justify-content: center;
  justify-content: space-around;
}

.login-selected{
  border-bottom: 2px solid;
  border-color: var(--button-color-primary);
}

.login-unselected{
  cursor: pointer;
}

.timerdiv{
  margin-top: 16px;
}


/* Sobescrever elementos que já existem */

.ReactModal__Overlay{
  background-color: var(--modal-background-color) !important;
}

.ReactModal__Content{
  background-color: var(--background-color) !important;
  .contextModal{
    background-color: var(--background-color) !important;
  }

}


/* Estilos dos componentes globais */

.global-panel{
  padding: 24px;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));
  text-decoration: unset;
  color: var(--text-color-primary);
  gap: 10px;
  text-align: center;
  border-radius: 18px;
  background-color: var(--secundary-color);
  width: 100%;
  max-width: 512px;
}

.global-miniW{
  max-width: 480px;
}


.global-infoPanel{
  padding: 24px;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));
  color: var(--text-color-primary);
  border: 1px solid var(--separator-color-primary);
  border-radius: 18px;
  width: 100%;
}

.global-infoPanel + .global-infoPanel{
  margin-top: 16px;
}

.global-table{
  background-color: transparent !important;
  table{
    background-color: transparent !important;
  }
  th{
    background-color: transparent !important;
  }
}

.global-mt{
  margin-top: var(--default-spacing);
}

.global-fullW{
  width: 100%;
}


.global-buttonWrapper{
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.global-buttonWrapper-toEnd{
  display: flex;
  justify-content: flex-end;
}

.global-button{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s;
  background-color: var(--button-color-primary);
  color: var(--text-color-secondary);
  font-weight: bold;
  border: none;
}

.global-button-wrong{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s;
  color: var(--text-color-secondary);
  font-weight: bold;
  border: none;
  background-color: var(--button-color-primary-wrong);
}


.global-button-wrong:hover{
  background-color: var(--button-color-primary-wrong-hover);
}

.global-button-right{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s;
  color: var(--text-color-secondary);
  font-weight: bold;
  border: none;
  background-color: var(--button-color-primary-right);
}

.global-button-right:hover{
  background-color: var(--button-color-primary-right-hover);
}


.global-button:hover{

  background-color: var(--button-color-primary-hover);
}

.global-button--full-width{
  width: 100% !important;
}

.global-button--mt{
  margin-top: var(--default-spacing);
}

.global-button--basic{
  width: 100% !important;
  background-color: transparent;
  color: var(--text-color-primary);
  font-weight: 100;
}

.global-button--transparent{
    background-color: var(--background-color);
    color: var(--button-color-primary);
    border: 1px solid var(--button-color-primary);
}

.global-button--transparent:hover{
    color: var(--text-color-secondary);
    background-color: var(--button-color-primary);
    border: 1px solid var(--button-color-primary);
}

.global-pageContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  height: 100%;
  margin-bottom: 48px;
  padding: 20px 8px;
  color: var(--text-color-primary);
  max-width: 1080px;
  width: 100%;
}

.global-pageContainer-left{
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 48px;
  padding: 20px 8px;
  color: var(--text-color-primary);
  max-width: 1080px;
  width: 100%;
}

.pageContainer{
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 48px;
  padding: 20px 8px;
  color: var(--text-color-primary);
  width: 100%;
  min-width: 20rem;
}

.global-extraBottom{
  margin-bottom: 80px;
}

.todasRespostas{
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
  justify-content: left;
}

.global-respostasLista{
  padding: 20px;
  justify-items: left;
  border: 1px solid var(--panel-border-color);
}

table{
  width: 100%;
}

.login input{
  width: 100%;
  padding: 8px;
  border-radius: 4px;
}

.global-input{
  width: 100%;
  padding: 8px;
  border-radius: 10px;
}

.not-found{
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found h1{
  font-size: 120px;
}

.not-found a{
  text-decoration: none;
  background-color: #116feb;
  color: #FFF;
  padding: 10px;
  margin-top: 14px;
}

.container a{
  text-decoration: none;
  background-color: beige;
  color: #000000;
  padding: 10px;
  margin-top: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.container a:hover{
  background-color: rgb(37, 26, 166);
  color: #FFF;
}

.respostasQuestoes{
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.respostasQuestoes h2{
  margin: 16px;
}

.formulario{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  width: 70%;
  align-self: center;
}

.formulario h3{
  margin-top: 16px;
  margin-bottom: 16px;
}

.formulario input{
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.botoes button{
  margin-top: 8px;
}

.table{
  text-align: center;
  width: 100%;
}

.contextModal{
  display: flex;
  flex-direction: column;    
  background-color: #424242;
  width: 100%;
  color: #fff;
  
}

.tituloModal{
  display: flex;
  justify-content: left;
  border: 5px;
}

.bodymodal{
  display: flex;
  flex-direction: column;    
  justify-content: left;
  margin: 32px;
  border: 5px;
}

.description{
  width: 100%;
}

.botoes{
margin-top: 50px;
margin-bottom: 50px;
align-self: center;
width: 70%;
}

.botoes a{
margin: 10px;
}

.container a{
  text-decoration: none;
  background-color: beige;
  padding: 10px;
  margin-top: 14px;
}

.container a:hover{
  background-color: rgb(37, 26, 166);
  color: #FFF;
}

.not-found a{
  text-decoration: none;
  background-color: #116feb;
  color: #FFF;
  padding: 10px;
  margin-top: 14px;
}

.tempo{
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  justify-content: space-between;
}

.info-completo{
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.info-tempo{
  margin-top: 8px;
}

.info-tempo2{
  margin-top: 16px;
  display: flex;
  justify-content: end;
}

.tempo a{
  margin-top: 8px;
  padding: 8px 16px;
  background-color: var(--button-color-primary);
  cursor: pointer;
}

.divJogo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divJogo h3{
  font-size: 150px;
}

.divJogo input{
  align-items: center;
  text-align: center;
  width: 50%;
  font-size: 110px;
}

.rankingMultiplicacao{
  width: 100%;
}

.divDescricao{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
}
.divDescricao h4{
  width: 20%;
  max-width: 20%;
}

.quantidade{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
  font-size: large;
}

hr{
  width: 100%;
  margin: 10px;
  color: #FFF;
  background-color: #FFF;   
}

.opcao{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.loaddingDiv{
  width: 90%;
  height: 100%;
  min-height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rankingText{
  width: 100%;
}

.tableRanking{
  border: white;
  border-bottom: dotted;
  padding-bottom: 20px;
}

.tableRanking tr{
  height: 35px;
  width: 100%;
}

.botoesRanking{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.botoesRanking button{
  margin: 4px;
}

.opcoes{
  margin-top: 50px;
  min-width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--loader-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loader-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.timerdiv{
  font-size: 25px;
}

.center {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 90vh; /* Ocupa toda a altura da tela */
  width: 100%; /* Garante que ocupa toda a largura */
}

.botoes-home{
  display: flex;
  flex-direction: column;
  gap: 16px; /* Espaço entre os botões */
  width: 100%;
  max-width: 300px; /* Limita a largura dos botões */
}

.game{
  margin-top: 2rem;
}

.resultados-lista{
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--double-default-spacing);
}

.resultados-item{
  background-color: var(--color-surface);
  min-width: 10rem;
  padding: var(--double-default-spacing);
  border-radius: var(--default-border-radius);
  box-shadow: 0 2px 5px var(--shadow-color-primary);
  text-align: center;
  font-weight: bold;
  color: var(--text-color-primary);
}

.admin-table {
  width: 100%;
  min-width: 20rem;
  border-collapse: collapse;
}

.admin-table th, .admin-table td {
  padding: 0.75rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.admin-table th {
  font-weight: bold;
}

.admin-table .action-icons {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 1000px) {
  .sidebar-toggle-btn {
    display: block;
  }
  
  .admin-options-top {
    display: block; /* Show only on screens 768px wide or smaller */
    position: absolute;
    top: 10px; /* Adjust position as needed */
    left: 10px;
    z-index: 1000; /* Ensure it appears above other elements */
  }

  .admin-content {
    display: none; /* Hide sidebar by default on mobile */
  }

  .admin-content.visible {
    display: block; /* Show sidebar when visible */
  }

  /* Make main content full width on mobile */
  .admin-main {
    margin-left: 0;
    width: 100%;
  }

  .admin-main > main {
      flex-grow: 1; /* Take up the remaining space */
      padding: 8px;
  }

  .container-admin-page {
      margin: 0.5rem;
      padding: 0.5rem;
  }

  .admin-table thead {
      display: none; /* Hide table headers */
  }

  .admin-table tbody {
      display: flex;
      flex-direction: column;
      gap: var(--double-default-spacing);
  }

  .admin-table tr {
      display: flex;
      flex-direction: column;
      color: var(--text-color-primary);
      background: var(--color-surface);
      padding: var(--default-spacing);
      border-radius: var(--default-border-radius);
      box-shadow: 0 2px 5px var(--shadow-color-primary);
  }

  .admin-table td {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 8px 0;
  }

  .admin-table td::before {
      content: attr(data-label); /* Show column name as a label */
      font-weight: bold;
      color: var(--text-color-primary);
  }
}